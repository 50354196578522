$super-slow: 1s;
$slow: 0.8s;
$medium: 0.4s;
$super-medium: 0.5s;
$fast: 0.2s;
$super-fast: 0.1s;

@keyframes this_way_left_big {
    0% {margin-left: 0;}
    50% {margin-left: -10px;}
    100% {margin-left: 0;}
}

@keyframes this_way_left {
    0% {margin-left: 0;}
    50% {margin-left: -5px;}
    100% {margin-left: 0;}
}

@keyframes this_way_right {
    0% {margin-right: 0;}
    50% {margin-right: -5px;}
    100% {margin-right: 0;}
}

@keyframes this_way_right_footer {
    0% {margin-left: 0.5rem;}
    50% {margin-left: 0.8rem;}
    100% {margin-left: 0.5rem;}
}

@keyframes this_way_right_article {
    0% {margin-left: 0;}
    50% {margin-left: 5px;}
    100% {margin-left: 0;}
}

@keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.icon-spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
