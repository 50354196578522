.landing {
    &__hero {
        transition: $fast;
        background: #0b3f47;
        border-bottom-right-radius: 30px;
        padding: 2.5rem 1rem 0;
        &__inner {
            transition: $fast;
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            column-gap: 1rem;
        }
        strong {
            transition: $fast;
            color: $text-lightest-teal;
            font-size: 18px;
            line-height: 24px;
        }
        h1 {
            transition: $fast;
            font-size: 36px;
            line-height: 45px;
            color: $white;
            margin: 0.5rem 0;
        }
        p {
            color: #fff;
            font-size: 14px;
            line-height: 19px;
        }
        img {
            transition: $fast;
            width: 100%;
            max-width: 255px;
            margin-top: 20px;
            margin-bottom: -11px;
        }
    }
    &__body {
        background: #fff;
        max-width: 765px;
        margin: auto;
        transition: $fast;
        h2 {
            font-size: 28px;
            line-height: 35px;
            color: $text-dark-teal;
            margin: 2rem 0 1rem;
            transition: $fast;
        }
        strong,
        h3 {
            color: $text-light-teal;
            margin: 1em 0;
            display: block;
            line-height: 24px;
            font-size: 16px;
            transition: $fast;
        }
        &__inner {
            padding: 3.5rem 1rem;
            h2 {
                margin-top: 0;
            }
            a {
                margin-top: 0.5rem;
            }
            &-invite {
                margin: 2.25rem 0 2.75rem;
                padding: 2rem;
                display: flex;
                border: 1px solid $grey;
                border-radius: 6px;
                img {
                    max-width: 112px;
                    margin: 0 1rem 0 0;
                }
            }
        }
        &__video-container {
            margin: 2rem 0;
            position: relative;
            overflow: hidden;
            height: 195px;
            transition: $fast;
            iframe {
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                position: absolute;
            }
            &-cover {
                opacity: 1;
                visibility: visible;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
            }
            &-play {
                opacity: 1;
                visibility: visible;
                height: 52px;
                width: 52px;
                border-radius: 100%;
                background: $white;
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: $fast;
                &:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12.5px 0 12.5px 21px;
                    border-color: transparent transparent transparent $tertiary;
                    left: 57%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                &:focus-visible,
                &:hover,
                &:active {
                    background-color: $tertiary;
                    transition: $super-fast;
                    outline: none;
                    border-radius: 100% !important;
                    &:after {
                        border-color: transparent transparent transparent $white;
                    }
                }
                &:active {
                    background-color: $dark-tertiary;
                }
            }
            &-hide {
                transition: $slow;
                opacity: 0;
                visibility: hidden;
            }
        }
        &__buttons-container {
            display: flex;
            column-gap: 1rem;
            margin-top: 1.5rem;
            flex-wrap: wrap;
            row-gap: 1.25rem;

            a {
                margin-top: 0;
            }
        }
        &__cta-container {
            transition: $fast;
            background: $light-teal;
            border-radius: 10px;
            padding: 2.5rem 1.5rem;
            margin: 2.5rem 0;
            strong {
                color: $text-dark-grey;
            }
            h2 {
                margin-top: 0;
                transition: $fast;
            }
            p {
                transition: $fast;
                font-size: 12px;
                line-height: 17.5px;
            }
            a {
                transition: $fast;
                min-width: 165px;
                &:focus-visible {
                    box-shadow: 0 0 0 3px $tertiary;
                    border-radius: 5px;
                    border: 2px solid $white;
                    outline: none;
                }
            }
        }
    }
}

@media (min-width: 590px) {
    .landing {
        &__body {
            &__video-container {
                height: 300px;
                &-play {
                    height: 100px;
                    width: 100px;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 23.5px 0 23.5px 39px;
                        border-color: transparent transparent transparent
                            $tertiary;
                        left: 56%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .landing {
        &__hero {
            border-bottom-right-radius: 100px;
            padding: 2.5rem 1rem 2.5rem 7.25rem;
            &__inner {
                flex-wrap: nowrap;
                justify-content: space-between;
                max-width: 765px;
                margin: auto;
            }
            &__text__container {
                max-width: 360px;
                &-error {
                    align-self: flex;
                    padding: 2.5rem 0;
                    h1 {
                        max-width: 300px;
                        margin: 2.5rem 0;
                    }
                }
            }
            img {
                width: 100%;
                max-width: 255px;
                margin-top: 0;
                margin-bottom: -110px;
            }
            strong,
            h3 {
                font-size: 20px;
                line-height: 28px;
            }
            h1 {
                font-size: 32px;
                line-height: 40px;
            }
        }
        &__body {
            padding: 0 6.25rem 0;
            h2 {
                font-size: 24px;
                line-height: 32px;
            }
            &__inner {
                padding: 3.5rem 1rem 6.25rem;
            }
            &__video-container {
                margin: 2rem 0 3.5rem;
                height: 330px;
            }
            &__cta-container {
                padding: 3rem;
                margin: 3.5rem -2rem;
                p {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
            &-error {
                max-width: 900px;
                padding: 0 6.25rem 0;
                margin: auto;
            }
        }
    }
}

@media (min-width: $breakpoint-md) {
    .landing {
        &__hero {
            padding: 4rem 1rem 4rem 7.25rem;
            &__inner {
                max-width: 1100px;
            }
            &__text__container {
                max-width: none;
                &-error {
                    h1 {
                        max-width: 500px;
                    }
                }
            }
            img {
                width: 100%;
                max-width: 312px;
                margin-bottom: -90px;
            }
            strong,
            h3 {
                line-height: 32px;
            }
            h1 {
                font-size: 40px;
                line-height: 60px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
                max-width: 480px;
                margin-bottom: 0;
            }
        }
        &__body {
            max-width: 1024px;
            padding: 0 11.5rem 0;
            h2 {
                font-size: 32px;
                line-height: 40px;
            }
            &__inner {
                padding: 3.5rem 0 4rem;
                &-invite {
                    margin: 2.25rem -7rem 2.75rem;
                    padding: 3.5rem;
                    border: 1px solid $grey;
                    border-radius: 10px;
                    img {
                        max-width: 147px;
                        margin: 0 3.5rem 0 0;
                    }
                }
            }
            &__video-container {
                height: 350px;
            }
            &__cta-container {
                padding: 3.5rem;
                margin: 3.5rem -3.5rem;
            }
            &-error {
                max-width: 900px;
                padding: 0 6.25rem 0;
                margin: auto;
            }
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .landing {
        &__hero {
            padding: 3rem 1rem 0rem 15rem;
            &__inner {
                max-width: 1300px;
            }
            &__text__container {
                align-self: flex-start;
                padding: 2.5rem 0;
                &-error {
                    align-self: flex;
                    padding: 2.5rem 0;
                    h1 {
                        max-width: 500px;
                        margin: 2.5rem 0;
                    }
                }
            }
            strong,
            h3 {
                font-size: 24px;
                line-height: 32px;
            }
            h1 {
                font-size: 48px;
                line-height: 60px;
            }
            p {
                max-width: 465px;
            }
            img {
                max-width: 470px;
                margin-bottom: -20px;
            }
        }
        &__body {
            padding: 0 8rem;
            &__inner {
                padding: 5.25rem 0 8.25rem;
            }
            &__video-container {
                height: 430px;
            }
            &__cta-container {
                padding: 3.5rem 6rem;
                margin: 3.5rem -6rem;
            }
        }
    }
}
