.invite {
    &__hero {
        transition: $fast;
        background: $light-teal;
        border-bottom-right-radius: 30px;
        padding: 2rem 1rem;
        &__inner {
            transition: $fast;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        h1 {
            transition: $fast;
            font-size: 36px;
            line-height: 45px;
            color: $text-dark-teal;
            margin: 0.5rem 0 1rem;
        }
        p {
            transition: $fast;
            font-size: 14px;
            line-height: 19px;
        }
        img {
            transition: $fast;
            width: 100%;
            max-width: 210px;
            margin-top: 1rem;
            margin-bottom: -49px;
        }
    }
    &__body {
        transition: $fast;
        background: $white;
        padding: 1rem;
        h2 {
            transition: $fast;
            color: $text-dark-teal;
            font-size: 28px;
            line-height: 35px;
            margin: 2rem 0 1rem;
        }
        &__inner {
            background: $white;
            &__form {
                form {
                    margin: 1rem 0;
                }
                input[type="email"] {
                    margin: 0 0 1rem;
                    width: 100%;
                    border: 1px solid $dark-grey;
                    border-radius: 4px;
                    padding: 0.75rem 1rem;
                    font-size: 16px;
                    line-height: 24px;
                    transition: $fast;
                    &:disabled {
                        background-color: $grey;
                        cursor: not-allowed;
                    }
                }
                label {
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: $font-heavy;
                    color: $text-dark-teal;
                    padding: 0 0 0.25rem 0;
                    display: block;
                }
                &-email {
                    position: relative;
                    i {
                        position: absolute;
                        right: 0;
                        margin: 17px 0;
                        color: $tertiary;
                        cursor: pointer;
                    }
                }
                a {
                    color: $tertiary;
                    font-weight: $font-heavy;
                    position: relative;
                    display: block;
                    margin: 0 5px;
                    transition: $fast;
                    span {
                        padding: 0rem 0.5rem;
                        position: absolute;
                        top: 4px;
                    }
                    i {
                        color: $white;
                        background: $tertiary;
                        border-radius: 100%;
                        padding: 0.1rem;
                        font-size: 1.5rem;
                        display: inline-block;
                        transition: $fast;
                    }
                }
                .button {
                    width: 100%;
                    margin: 1.5rem auto 2rem;
                }
            }
            &__item {
                display: flex;
                border-top: 1px solid $grey;
                margin: 1rem 0;
                padding: 1.5rem 0 1rem;
                h2 {
                    margin: 1rem 0 1rem 0;
                }
                .button {
                    margin: 0.5rem 0;
                    max-width: 259px;
                    width: 100%;
                }
                a {
                    color: $tertiary;
                    font-weight: $font-heavy;
                    border-bottom: 2px solid $tertiary;
                    line-height: 18px;
                    display: inline-block;
                }
            }
            a {
                transition: $fast;
                &:hover {
                    @media(hover: hover) {
                        color: $dark-tertiary;
                        border-color: $dark-tertiary;
                        transition: $fast;
                        i {
                            background: $dark-tertiary;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: $breakpoint-sm) {
    .invite {
        &__hero {
            border-bottom-right-radius: 100px;
            padding: 2.5rem 1rem;
            h1 {
                margin-top: 0px;
                font-size: 32px;
                line-height: 40px;
            }
            img {
                max-width: 260px;
                margin-bottom: -55px;
            }
            &__text-container {
                max-width: 400px;
            }
            &__inner {
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                max-width: 765px;
                margin: auto;
            }
        }
        &__body {
            h2 {
                font-size: 24px;
                line-height: 32px;
            }
            &__inner {
                max-width: 710px;
                margin: auto;
                &__form {
                    .button {
                        width: auto;
                    }
                }
            }
        }
    }
}

@media(min-width: $breakpoint-md) {
    .invite {
        h2 {
            font-size: 32px;
            line-height: 40px;
        }
        &__hero {
            padding: 3.5rem 1rem 3.5rem 4rem;
            h1 {
                font-size: 40px;
                line-height: 60px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
            img {
                max-width: 370px;
                margin-top: 0;
                margin-bottom: -75px;
            }
            &__inner {
                max-width: 1130px;
            }
            &__text-container {
                max-width: 550px;
            }
        }
        &__body {
            padding: 2rem 1rem;
            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
}

@media(min-width: $breakpoint-xl) {
    .invite {
        &__hero {
            padding: 3.5rem 1rem 3.5rem 4rem;
            h1 {
                font-size: 48px;
                line-height: 60px;
            }
            &__inner {
                max-width: 1168px;
            }
            &__text-container {
                max-width: 705px;
            }
            p {
                max-width: 550px;
            }
            img {
                max-width: 408px;
                margin-bottom: -80px;
            }
        }
        &__body {
            padding: 3.5rem 1rem 2.5rem;
            h2 {
                margin-top: 0;
            }
            &__inner {
                max-width: 768px;
                &__item {
                    margin: 2rem 0;
                    padding: 3.5rem 0 1rem;
                }
            }
        }
    }
}

.invite-status {
    color: $tertiary;
    display: inline-block;
    margin-right: 0.75rem;
    font-size: 13.5px;
}