//general
$white: rgb(255,255,255);
$grey: rgba(235, 235, 235, 1);
$dark-grey: rgba(205, 211, 214, 1);
$darkest-grey: rgba(73, 80, 84, 1);
$light-teal: rgba(232, 246, 244, 1);
$medium-teal: rgba(209, 238, 234, 1);
$dark-teal: rgba(11, 63, 71, 1);
$tertiary: rgba(128, 85, 241, 1);
$dark-tertiary: rgba(68, 18, 112, 1);
$error:  rgba(247, 231, 235, 1);
//text
$text-lightest-teal: rgba(209, 238, 234, 1);
$text-light-teal: rgba(46, 128, 142, 1);
$text-dark-teal: rgba(11, 63, 71, 1);
$text-dark-grey: rgba(34, 39, 43, 1);
$text-medium-grey: rgba(73, 80, 84, 1);
$text-light-grey: rgba(205, 211, 214, 1);