*,
*:after,
*:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    box-sizing: border-box;
    //outline: none !important;
}

body,
html {
    margin: 0;
    padding: 0;
}

html {
    min-height: 100vh;
}

body {
    font-family: $font_family_main;
    font-size: $font_size_base;
    -webkit-overflow-scrolling: touch;
}

.viewport {
    height: 100vh;

    &--min {
        height: auto;
        min-height: 100vh;
    }
}

a,
img {
    border: none;
}

a {
    text-decoration: none;
}

input,
select,
select:focus,
textarea {
    border-radius: 0;
    appearance: none;
    font-family: $font_family_main;
}

button:focus-visible, input[type="submit"]:focus-visible, input[type="email"]:focus-visible{
    box-shadow: 0 0 0 3px $tertiary;
    border-radius: 3px;
    outline: none;
}

a:focus-visible, p:focus-visible, span:focus-visible, i:focus-visible, input[type="email"]:focus-visible,
h2:focus-visible, h3:focus-visible, div:focus-visible, label:focus-visible, b:focus-visible, h1:focus-visible,
small:focus-visible {
    box-shadow: 0 0 0 3px $tertiary;
    border-radius: 3px;
    outline: none;
}

.grecaptcha-badge {
    z-index: 1;
}