@font-face {
    font-family: Public-Sans;
    src: local("Public-Sans"), url("../../assets/fonts/PublicSans-Regular.woff") format("woff");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: Public-Sans;
    src: local("Public-Sans-Bold"), url("../../assets/fonts/PublicSans-Bold.woff") format("woff");
    font-weight: bold;
    font-display: swap;
}

$font_family_main: Public-Sans, Arial, Helvetica, sans-serif;
$font_family_heading: Public-Sans, Arial, Helvetica, sans-serif;
$font_size_base: 16px;
$font_lineheight_base: 1.5em;

$font-heavy: 700;
$font-regular: 400;
