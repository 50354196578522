.sat {
    background: $light-teal;
    transition: opacity $medium;
    &__tooltip {
        font-weight: $font-heavy;
        border-bottom: 1px dashed;
        background: #e6e1fd;
        padding: 1px 3px;
        cursor: pointer;
        i {
            color: $tertiary;
            padding: 0 0 0 4px;
            line-height: inherit;
        }
    }
    &__tooltip-body {
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        position: absolute;
        max-width: calc(100vw - 2rem);
        margin: 1rem;
        background: $darkest-grey;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
        transition: opacity $fast, max-width $super-fast, height $super-fast,
            left $super-fast, top $super-fast;
        &-show {
            opacity: 1;
            visibility: visible;
            z-index: 4;
            border-radius: 4px;
            padding: 1rem;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            li,
            span,
            i,
            a,
            ul li p {
                color: $white;
            }
            a {
                text-decoration: underline;
                color: $white !important;
                border-bottom: none !important;
                font-weight: normal !important;
            }
            h4 {
                font-size: 16px;
                line-height: 24px;
                margin: 0 0 0.5rem;

                &::first-letter {
                    text-transform: capitalize;
                }
            }
            ul,
            ol {
                padding-left: 1.5rem;
            }
            li {
                padding-left: 4px;
                margin: 0.5rem 0;
            }
            p,
            li,
            span {
                font-size: 14px;
                line-height: 19px;
            }
            p {
                margin: 0 !important;
            }
            i {
                @media (hover: hover) {
                    cursor: pointer;
                }
                position: absolute;
                right: 1rem;
                top: 1rem;
                font-size: 18px;
            }
            &-pointer {
                height: 15px;
                width: 15px;
                background: $darkest-grey;
                position: absolute;
                top: -2px;
                left: 115px;
                transform: rotate(45deg);
            }
        }
    }
    &__inner {
        padding: 2rem 1rem 3.5rem;
        max-width: 568px;
        margin: auto;
    }
    &__section-intro {
        h2 {
            color: $text-dark-teal;
            font-size: 28px;
            line-height: 35px;
            margin: 0.5rem 0 0;
        }
        h3 {
            color: $text-light-teal;
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 0.5rem;
        }
        img {
            width: 100%;
            max-width: 200px;
            margin: 0 0 -4px 0;
        }
        p {
            margin: 1rem 0 1.5rem;
        }
    }
    &__form-item {
        margin: 1.25rem 0;
        &__about {
            .input__checkbox {
                border: none;
                div:first-child {
                    padding: 1rem 0 !important;
                }
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        &__question-no {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: $text-light-teal;
            margin: 0 0 4px 0;
        }
        &__markup {
            p {
                margin: 0.5rem 0;
                font-size: 14px;
                line-height: 19px;
            }
            b,
            label {
                font-size: 14px;
                line-height: 19px;
                font-weight: $font-heavy;
                color: $text-dark-teal;
                display: block;
            }
            ol,
            ul {
                padding: 0 0 0 1rem;
                margin: 1rem 0;
                font-size: 14px;
                font-weight: $font-heavy;
                color: $text-dark-teal;
            }
            ul {
                font-size: 20px;
            }
            li {
                margin: 0.5rem 0.15rem;
            }
            li p,
            ul p {
                font-weight: $font-regular;
                color: $text-dark-grey;
            }
            a {
                color: $tertiary;
                font-weight: $font-heavy;
                border-bottom: 1px solid $tertiary;
                display: inline-block;
                &:hover {
                    @media (hover: hover) {
                        color: $dark-tertiary;
                        border-color: $dark-tertiary;
                    }
                }
            }
            &-responsive {
                &-item {
                    width: 100%;
                    display: block;
                    margin-top: 1rem;
                }
            }
        }
        &-sub {
            margin: 0.5rem 0 0 0 !important;
        }
        &-super {
            margin: 0.5rem 0 0 !important;
        }
    }
    &__form-container {
        background: $white;
        padding: 1rem;
        border-radius: 6px;
        .sat__form-item:first-child {
            margin-top: 8px;
        }
        .button {
            width: calc(50% - 0.25rem);
        }
        .prev {
            margin: 0 0.5rem 0 0;
        }
        .next {
            margin-left: 0 0 0 0.5rem;
        }
        &-link {
            color: $tertiary;
            font-weight: $font-heavy;
            padding: 0 0 1px 0;
            border-bottom: 2px solid $tertiary;
            line-height: 18px;
            margin: 1rem 0 0;
            display: inline-block;
            &:hover {
                @media (hover: hover) {
                    color: $dark-tertiary;
                    border-color: $dark-tertiary;
                }
            }
            &--disabled {
                cursor: not-allowed;
                &:hover {
                    @media (hover: hover) {
                        color: $tertiary;
                        border-color: $tertiary;
                    }
                }
            }
        }
    }
    &__form-buttons {
        margin-top: 2rem;
        button:disabled {
            background: $dark-tertiary;
            border-color: $dark-tertiary;
            color: $white;
        }
    }
    &__form-submit-message {
        display: flex;
        align-items: top;
        margin: 1rem 0 0.5rem;
        i {
            font-size: 13px;
            margin-top: 3px;
        }
        p {
            margin: 0;
            font-size: 14px;
            line-height: 19px;
            max-width: 350px;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .sat {
        &__form-item {
            &__question-no {
                margin: 0 0 10px 0;
            }
            &__markup {
                &-responsive {
                    &-item {
                        width: calc(50% - 0.5rem);
                        display: inline-block;
                        margin-top: 0;
                    }
                }
            }
        }
        &__tooltip-body {
            max-width: 426px;
        }
        &__inner {
            padding: 2rem 0rem 3.5rem;
            margin: auto;
        }
        &__form-container {
            background: $white;
            padding: 2rem;
            border-radius: 6px;
        }
        .button {
            max-width: 175px;
        }
    }
    .sat__form-item__markup-responsive > div:nth-of-type(1) {
        margin-right: 0.5rem;
    }
    .sat__form-item__markup-responsive > div:nth-of-type(2) {
        margin-left: 0.5rem;
    }
}

@media (min-width: $breakpoint-md) {
    .sat {
        &__form-item {
            margin: 1.5rem 0;
        }
        &__inner {
            max-width: none;
            display: flex;
            justify-content: center;
            padding: 4rem 1rem 6.3rem;
        }
        &__form-container {
            min-width: 668px;
            max-width: 668px;
            padding: 3rem;
        }
        &__section-intro {
            margin: 0 3.5rem 0 0;
            max-width: 468px;
            h2 {
                font-size: 40px;
                line-height: 60px;
                margin: 0.5rem 0 0;
            }
            h3 {
                font-size: 20px;
                line-height: 32px;
                margin: 0 0 0.5rem;
            }
            img {
                margin: 1rem 0 0 0;
                max-width: none;
            }
        }
        &__form-buttons {
            margin-top: 2.5rem;
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .sat {
        &__inner {
            padding: 4rem 1rem 7.37rem;
        }
        &__section-intro {
            h2 {
                font-size: 48px;
            }
        }
    }
}

div:has(> iframe[title^="recaptcha"]) {
    position: fixed !important;
    top: 30px !important;
}

.sample-form {
    background: $light-teal;
    transition: $fast;
    border-bottom-right-radius: 30px;
    padding: 2rem 1rem 2rem;
    &__inner {
        transition: $fast;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        column-gap: 1rem;
    }
}

@media (min-width: $breakpoint-sm) {
    .sample-form {
        padding: 2rem 1rem 2rem 7.25rem;
        &__inner {
            justify-content: space-between;
            max-width: 765px;
            margin: auto;
            padding-right: 6.25rem;
        }
    }
}

@media (min-width: $breakpoint-md) {
    .sample-form {
        padding: 4rem 1rem 4rem 7.25rem;
        &__inner {
            flex-wrap: nowrap;
            max-width: 1100px;
        }
    }
}

@media (min-width: $breakpoint-xl) {
    .sample-form {
        padding: 4rem 1rem 4rem 15rem;
        &__inner {
            max-width: 1300px;
        }
        &__text__container {
            align-self: flex-start;
            padding: 2.5rem 0;
        }
    }
}
