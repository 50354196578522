.thankyou {
    h2 {
        font-size: 28px;
        line-height: 35px;
    }
    &__hero {
        background: $light-teal;
        border-bottom-right-radius: 30px;
        padding: 2rem 1rem 5rem;
        &__inner {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        h1 {
            transition: $fast;
            font-size: 36px;
            line-height: 45px;
            color: $text-dark-teal;
            margin-bottom: 1rem;
        }
        h3 {
            transition: $fast;
            font-size: 22px;
            line-height: 27.5px;
            color: $text-light-teal;
            margin: 1rem 0;
        }
        p {
            transition: $fast;
            font-size: 14px;
            line-height: 19px;
        }
        img {
            transition: $fast;
            width: 100%;
            max-width: 220px;
        }
    }
    &__cta {
        position: relative;
        &__inner {
            transition: $fast;
            z-index: 1;
            background: $dark-teal;
            border-radius: 6px;
            padding: 2.5rem 1.5rem 3rem;
            margin: -4.5rem 1rem 0;
            &-save {
                position: absolute !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                padding: 0.5rem 1.1rem !important;
                height: 50px;
                bottom: 0;
                right: 0;
            }
            &-email {
                width: 100%;
                position: relative;
            }
        }
        h2 {
            color: $white;
            margin-top: 0;
            text-align: center;
        }
        h3 {
            color: $white;
            margin-top: 0;
            text-align: center;
        }
        p {
            color: $white;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            margin: 1rem 0 2rem;
        }
        input[type="email"] {
            width: calc(100% - 115px) !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-right: none !important;
            &:disabled {
                background-color: $grey;
                cursor: not-allowed;
            }
        }
        input[type="submit"] {
            height: 50px;
            width: 115px;
            border: none;
            position: absolute;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            font-size: 16px;
            line-height: 24px;
            padding: 0;
        }
    }
    &__body {
        background: $white;
        padding: 1rem;
        h2 {
            color: $text-dark-teal;
            margin: 1rem 0 1rem;
            transition: $fast;
        }
        &__inner {
            background: $white;
            &__item {
                display: flex;
                border-top: 1px solid $grey;
                margin: 1rem 0;
                padding: 1.5rem 0 1rem;
                h3 {
                    margin: 0 0 1rem 0;
                    font-size: 22px;
                    line-height: 27.5px;
                    transition: $fast;
                }
                .t-icon {
                    font-size: 26px;
                    padding: 0 1rem 0 0;
                    min-width: 45px;
                    color: $text-light-teal;
                }
                .button {
                    margin: 0.5rem 0;
                    max-width: 259px;
                    width: 100%;
                }
            }
        }
    }
    &__subfooter {
        background: $light-teal;
        padding: 2.5rem 1rem 1.75rem;
        &__item {
            flex: 1;
            background: $white;
            border-radius: 4px;
            display: flex;
            margin: 0.75rem 0;
            a {
                margin: 0;
            }
        }
        &__inner {
            transition: $fast;
            h2 {
                color: $text-dark-teal;
                margin: 0 0 0.5rem;
                transition: $fast;
            }
            a {
                color: $tertiary;
                display: inline-block;
                span {
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: $font-heavy;
                    display: inline-block;
                    border-bottom: 2px solid $tertiary;
                    padding-bottom: 1px;
                    margin-right: 0.25rem;
                }
                i {
                    display: inline-block;
                }
                &:hover {
                    color: $dark-tertiary;
                    span {
                        border-color: $dark-tertiary;
                    }
                    i {
                        animation: this_way_right_article 500ms;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease;
                    }
                }
            }
        }
        &__articles {
            display: flex;
            flex-direction: column;
        }
        &__header{
            margin: 0 0 1.5rem 0;
            a {
                position: relative;
                margin-right: 1rem;
                display: inline-block;
                i {
                    position: absolute;
                    top: 1px;
                    right: -26px;
                }
                &:hover {
                  i {
                    animation: this_way_right 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                  }
                }
            }
        }
        &__item-content {
            width: 100%;
            flex: 63;
            padding: 1.5em;
            h3 {
                font-size: 18px;
                line-height: 24px;
                color: $dark-teal;
                margin: 0 0 0.5rem;
            }
        }
        &__item-img {
            transition: $fast;
            border-radius: 4px;
            width: 100%;
            flex: 37;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top left;
        }
    }
}

@media(min-width: 542px){
    .thankyou {
        &__subfooter {
            &__item-img {
                flex: 20;
            }
        }
    }
}

@media(min-width: 650px){
    .thankyou {
        &__subfooter {
            &__item-img {
                background-size: cover;
            }
        }
    }
}

@media(min-width: $breakpoint-sm) {
    .thankyou {
        h2 {
            font-size: 24px;
            line-height: 32px;
            span {
                white-space: nowrap;
            }
        }
        &__hero {
            overflow: hidden;
            border-bottom-right-radius: 100px;
            padding: 2.5rem 1rem 4.5rem;
            h1 {
                margin-top: 0px;
                font-size: 32px;
                line-height: 40px;
            }
            img {
                max-width: none;
                margin-top: 25px;
                margin-bottom: -35px;
            }
            &__text-container {
                max-width: 450px;
            }
            &__inner {
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: space-between;
                max-width: 765px;
                margin: auto;
            }
        }
        &__cta {
            margin: 0 1rem;
            &__inner {
                padding: 3rem 3.5rem 3rem;
                margin: -3rem auto 0;
                max-width: 765px;
            }
            &-email {
                max-width: 450px;
                margin: auto;
            }
        }
        &__body {
            &__inner {
                max-width: 710px;
                margin: auto;
            }
        }
        &__subfooter {
            &__inner {
                max-width: 765px;
                margin: auto;
            }
            &__articles {
                display: flex;
                flex-direction: row;
            }
            &__item:first-of-type {
                margin: 0.75rem 1rem 0.75rem 0;
            }
            &__header {
                display: flex;
                justify-content: space-between;
                margin: 0 0 0.5rem 0;
                a {
                    padding: 1px;
                }
            }
            &__item-img {
                background-size: contain;
                flex: 37;
            }
        }
    }
}

@media(min-width: $breakpoint-md) {
    .thankyou {
        h2 {
            font-size: 32px;
            line-height: 40px;
        }
        &__hero {
            padding: 3rem 1rem 4rem 4rem;
            h1 {
                font-size: 40px;
                line-height: 60px;
            }
            p {
                font-size: 16px;
                line-height: 24px;
            }
            img {
                max-width: 490px;
                margin-top: 0;
                margin-bottom: -25px;
            }
            &__inner {
                max-width: 1130px;
                align-items: center;
            }
            &__text-container {
                max-width: 550px;
            }
        }
        &__cta {
            &__inner {
                padding: 4rem 4.5rem 4rem;
                max-width: 900px;
                &-email {
                    max-width: 484px;
                    margin: auto;
                }
                h2 {
                    margin: 0 0 1rem;
                }
                p {
                    margin: 1rem 3rem 1.5rem;
                }
            }
        }
        &__body {
            padding: 2rem 1rem;
            &__inner {
                &__item {
                    h3 {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }
            }
        }
        &__subfooter {
            padding: 3rem 1rem 3.75rem;
            &__inner {
                max-width: 900px;
            }
            &__item:first-of-type {
                margin: 0.75rem 2rem 0.75rem 0;
            }
            &__header {
                a {
                    padding: 0.5rem;
                    i {
                        top: 9px;
                        right: -18px;
                    }
                }
            }
            &__item-img {
                background-position: top left;
                flex: 25;
            }
        }
    }
}

@media(min-width: $breakpoint-xl) {
    .thankyou {
        &__hero {
            padding: 5rem 1rem 7rem;
            h1 {
                font-size: 48px;
                line-height: 60px;
            }
            h3 {
                font-size: 24px;
                line-height: 32px;
            }
            p {
                font-size: 20px;
                line-height: 28px;
            }
            &__inner {
                max-width: 1168px;
            }
            &__text-container {
                max-width: 645px;
            }
            img {
                max-width: 667px;
                margin-bottom: -80px;
                margin-right: -170px;
            }
        }
        &__cta {
            &__inner {
                padding: 5rem 4.625rem;
                max-width: 1168px;
                &-email {
                    max-width: 564px;
                    margin: auto;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        &__body {
            padding: 3.5rem 1rem 2.5rem;
            &__inner {
                max-width: 768px;
                &__item {
                    padding: 2rem 0 1rem;
                }
            }
        }
        &__subfooter {
            padding: 5rem 0 6rem;
            &__inner {
                max-width: 1168px;
            }
            &__item-img {
                background-position: top left;
                flex: 31;
            }
        }
    }
}