.header-progress-bar {
    width: 100%;
    span {
        color: $text-light-teal;
        font-weight: $font-heavy;
        font-size: 14px;
    }
    &__indicator {
        width: 100%;
        height: 8px;
        background: $medium-teal;
        border-radius: 10px;
        margin-top: 0.5rem;
    }
    &__indicator-fill {
        height: inherit;
        background: $dark-teal;
        border-radius: 10px;
        max-width: 100%;
        transition: $super-medium;
    }
}

@media(min-width: $breakpoint_sm) {
    .header-progress-bar span {
        font-size: 16px;
    }
}

@media(min-width: $breakpoint_md) {
    .header-progress-bar span {
        font-size: 20px;
    }
}
