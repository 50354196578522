.header {
    padding: 0;
    border-bottom-left-radius: 6px;
    margin-bottom: -4px;
    border-bottom-right-radius: 6px;
    background: $white;
    position: relative;
    &__skip-link {
        position: absolute;
        left: 50%;
        top: -10rem;
        z-index: 5;
        transform: translateX(-50%);
        padding: 0.5rem;
        border-radius: 6px;
        box-shadow: 0 0 10px #00000017;
        border: 1px solid $grey;
        color: $tertiary;
        font-weight: $font-heavy;
        font-size: 14px;
        transition: $medium;
        &:focus {
            top: 1rem;
        }
        &:focus-visible {
            outline: 2px solid $tertiary;
        }
    }
    &__container {
        max-width: 1680px;
        margin: auto;
    }
    &__logo-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 71px;
        max-width: 175px;
        img {
            width: 100%;
            max-width: 263px;
        }
    }
    &__link-container-m {
        display: block;
        margin-right: 16px;
    }
    &__link-container-back {
        margin-right: 16px;
        align-items: center;
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        span {
            display: block;
            margin: 8px auto 0;
            font-size: 12px !important;
        }
        i {
            font-size: 12px !important;
            width: auto !important;
            margin: 0;
        }
        @media (hover: hover) {
            &:hover {
                i {
                    animation: this_way_left_big 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
    &__previous-step {
        p {
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: $text-dark-teal;
            font-weight: $font-heavy;
            max-width: 70px;
            margin: 8px auto 0;
        }
        i {
            color: $text-dark-teal;
            display: block;
            text-align: center;
            font-size: 14px;
            transition: $fast;
        }
    }
    &__link {
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        margin: 0.3rem;
        display: inline-flex;
        align-items: center;
        color: $text-dark-teal;
        cursor: pointer;
        i {
            margin: 0 0.3rem 0 0;
            display: block;
            width: 16px;
            font-size: 16px;
        }
        &-language {
            position: absolute;
            margin-top: 6px;
            overflow: hidden;
            transition: $fast;
            max-height: 0;
            background: $white;
            z-index: 1;
            text-align: left;
            border-radius: 4px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            right: 6px;
            max-width: 250px;
            width: 100%;
            padding: 0.75rem 2rem;
            visibility: hidden;
            opacity: 0;
            border: 1px solid $grey;
        }
    }
    &__language-select {
        display: inline-block;
        p {
            cursor: pointer;
            margin: 0.75rem 0;
            @media (hover: hover) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .active {
            color: $tertiary;
            text-decoration: underline;
        }
        small {
            color: $text-medium-grey;
            margin: 0.75rem 0 1rem;
            display: block;
        }
        &-down {
            transition: $super-fast;
            position: absolute;
            font-size: 7px !important;
            bottom: 4px;
            margin: 0 !important;
            right: -21px;
        }
    }
    &__error {
        padding: 1rem 2.5rem 1rem 1rem;
        background: $error;
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        position: fixed;
        z-index: 4;
        .icon-close {
            right: 0;
            top: 0;
            margin: 1rem;
            position: absolute;
            color: $text-medium-grey;
            cursor: pointer;
        }
    }
    .show {
        max-height: 500px;
        display: block;
        visibility: visible;
        opacity: 1;
    }
    .rotate {
        transition: $super-fast;
        transform: rotate(-180deg);
        right: -17px;
        bottom: 5px;
    }
    &__progress-border {
        border-top: 1px solid $grey;
    }
    &__previous-step-container {
        height: 86px;
        max-width: 105px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $grey;
        cursor: pointer;
        &--disabled {
            cursor: not-allowed;
        }
        @media (hover: hover) {
            &:hover {
                i {
                    animation: this_way_left_big 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                }
            }
            &--disabled:hover {
                i {
                    animation: unset;
                }
            }
        }
    }
    &__progress-bar-container {
        height: 86px;
        padding: 0 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    &__link-container {
        display: none;
        transition: $fast;
    }
    &__language-select-container {
        margin-left: 2em;
        display: inline-block;
    }
}

@media (min-width: $breakpoint_sm) {
    .header {
        &__logo-container {
            height: 90px;
            max-width: 215px;
            padding: 0 30px;
            border-right: 1px solid $grey;
            img {
                width: 100%;
            }
        }
        &__link-container-back {
            border-right: 1px solid $grey;
            flex-grow: 0;
            justify-content: center;
            height: 90px;
            width: 90px;
            span {
                font-size: 14px !important;
            }
            i {
                font-size: 14px !important;
            }
        }
        &__link-container-m {
            display: none;
        }
        &__link {
            i {
                margin: 0 0.4rem 0 0;
            }
            span {
                font-size: 14px;
            }
            &-language {
                right: 0;
            }
        }
        &__progress-border {
            border-top: none;
        }
        &__previous-step-container {
            height: 90px;
        }
        &__progress-bar-container {
            height: 90px;
            padding: 0 30px;
        }
        &__link-container {
            display: block;
            position: absolute;
            right: 30px;
            top: 17px;
        }
        &__language-select {
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

@media (min-width: $breakpoint_md) {
    .header {
        &__logo-container {
            height: 117px;
            justify-content: start;
            max-width: 375px;
            img {
                width: 100%;
            }
        }
        &__link {
            span {
                font-size: 16px;
            }
            i {
                margin: 0 0.75rem 0 0;
                font-size: 18px;
                &.icon-globe {
                    font-size: 22px;
                }
            }
        }
        &__link-container-back {
            border-right: 1px solid $grey;
            height: 117px;
            width: 105px;
            i {
                margin: 0;
            }
        }
        &__previous-step-container {
            height: 117px;
        }
        &__progress-bar-container {
            height: 117px;
        }
        &__link-container {
            top: 25px;
        }
        &__language-select {
            &-down {
                font-size: 8px !important;
                bottom: 6px;
                right: -22px;
            }
        }
        .rotate {
            right: -19px;
            bottom: 7px;
        }
    }
}
