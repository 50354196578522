.input {
    word-break: break-word;
    &__message {
        display: block;
        font-size: 14px;
        font-weight: $font-heavy;
    }
    &__radio,
    &__checkbox {
        width: 100%;
        border: 1px solid $dark-grey;
        border-radius: 4px;
        cursor: pointer;
        label {
            color: $text-dark-grey;
            font-size: 16px;
            font-weight: $font-regular;
            line-height: 24px;
            margin-bottom: 0;
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            padding: 1rem;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            &:nth-child(odd) {
                border: none;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        &-element {
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    > input[type="radio"] {
                        background: $tertiary;
                        box-shadow: 0 0 0 4px $white inset;
                    }
                    > input[type="checkbox"] {
                        background: $tertiary;
                        border: 1px solid $tertiary;
                    }
                }
            }
        }
    }
    input[type="radio"] {
        border-radius: 100%;
        border: 1px solid $dark-grey;
        height: 32px;
        width: 32px;
        min-width: 32px;
        margin-right: 1rem;
        transition: $fast;
        align-self: flex-start;
        position: relative;
        cursor: pointer;
        &:checked {
            border-color: $tertiary;
            background: $tertiary;
            box-shadow: 0 0 0 4px $white inset;
            &:after {
                opacity: 1;
                background: $tertiary;
            }
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background: $tertiary;
                box-shadow: 0 0 0 4px $white inset;
            }
            &:disabled:hover {
                box-shadow: none;
            }
        }
        &:disabled {
            background-color: $dark-grey;
            cursor: not-allowed;
        }
        &:focus-visible {
            outline: 3px solid $tertiary;
            transition: $super-fast;
        }
    }
    input[type="checkbox"] {
        border-radius: 4px;
        border: 1px solid $dark-grey;
        height: 32px;
        width: 32px;
        min-width: 32px;
        margin-right: 1rem;
        transition: $fast;
        align-self: flex-start;
        position: relative;
        cursor: pointer;
        &:after {
            position: absolute;
            top: calc(50%);
            left: calc(50%);
            transform: translate(-50%, -50%);
            color: $white;
            font-size: 10px;
            content: "\f109";
            font-family: Iconfont !important;
            padding: 5px 4px;
            border-radius: 3px;
            opacity: 0;
            transition: $fast;
        }
        &:checked {
            border-color: $tertiary;
            &:after {
                opacity: 1;
                background: $tertiary;
            }
        }
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background: $tertiary;
                border: 1px solid $tertiary;
            }
            &:disabled:hover {
                box-shadow: none;
            }
        }
        &:disabled {
            background-color: $dark-grey;
            cursor: not-allowed;
        }
        &:focus-visible {
            outline: 3px solid $tertiary;
            transition: $super-fast;
        }
    }
    &__text {
        input[type="text"],
        input[type="email"],
        input[type="tel"] {
            width: 100%;
            border: 1px solid $dark-grey;
            border-radius: 4px;
            padding: 0.75rem 1rem;
            font-size: 16px;
            line-height: 24px;
            margin: 0.5rem 0 0;
            transition: $super-fast;
            &:disabled {
                background-color: $grey;
                cursor: not-allowed;
            }
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 3px $tertiary;
            }
        }
        &-button {
            padding: 0;
            font-size: 16px;
            border: none;
            position: absolute;
            right: 1rem;
            top: calc(50% + 3px);
            transform: translateY(-50%);
            cursor: pointer;
            background: none;
            font-weight: 700;
            color: $tertiary;
            border-bottom: 1px solid #8055f1;
            display: inline-block;
            transition: $medium;
            &:disabled {
                cursor: not-allowed;
                color: $text-light-grey;
                border-bottom: 1px solid $text-light-grey;
            }
        }
        &__abn-lookup-results {
            position: absolute;
            background: white;
            z-index: 1;
            width: 100%;
            margin-top: 1rem;
            max-height: 400px;
            border-radius: 6px;
            overflow: scroll;
            padding: 0.5rem 1rem;
            border: 1px solid #f0f0f0;
            box-shadow: 0 0 2rem #80808030;
            p {
                text-transform: capitalize;
                cursor: pointer;
            }
            strong {
                font-size: 14px;
                line-height: 19px;
                font-weight: 700;
                display: block;
                color: $text-dark-teal;
                margin-top: 0.5rem;
            }
        }
        &__abn-lookup-results--error {
            background-color: $error;
        }
        &-wrapper {
            position: relative;
        }
    }
    &__textarea {
        border: none !important;
        padding: 0 1rem 1rem !important;
        textarea {
            transition: $super-fast;
            width: 100%;
            border-radius: 4px;
            min-height: 130px;
            padding: 10px;
            resize: vertical;
            border: 1px solid $dark-grey;
            font-size: 16px;
            line-height: 24px;
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 3px $tertiary;
            }
        }
        &-character-count {
            padding: 0rem 1rem 1rem;
            width: 100%;
            display: block;
            font-size: 14px;
            color: $text-medium-grey;
        }
    }
    &__select {
        select {
            width: 100%;
            border: 1px solid $dark-grey;
            border-radius: 4px;
            padding: 0.75rem 1rem;
            font-size: 16px;
            line-height: 24px;
            background: $white;
            position: relative;
            cursor: pointer;
            &:focus-visible {
                outline: none;
                box-shadow: 0 0 0 3px $tertiary;
            }
        }
        &-input-wrapper {
            position: relative;
            &:after {
                position: absolute;
                right: 1rem;
                top: calc(50% + 2px);
                transform: translateY(-50%);
                color: $text-dark-teal;
                font-size: 10px;
                content: "\f10a";
                font-family: Iconfont !important;
                padding: 0.5rem 0rem;
                background: $white;
            }
        }
    }
    &__slider {
        &:active {
            cursor: grabbing;
        }
        position: relative;
        height: 4rem;
        user-select: none;
        &-track {
            transition: $fast;
            position: absolute;
            top: 11px;
            height: 10px;
            border-radius: 50px;
            &-0 {
                background: $tertiary;
                position: absolute;
                .z {
                    border-radius: 50px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 3;
                    background: $tertiary;
                }
            }
            &-1 {
                background: $grey;
                z-index: 0;
            }
            &:active {
                cursor: grabbing;
            }
        }
        &-thumb {
            height: 2rem;
            width: 2rem;
            background: $white;
            border: 2px solid $grey;
            border-radius: 100%;
            text-align: center;
            box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
            font-size: 18px;
            font-weight: $font-heavy;
            color: $white;
            transition: $fast;
            z-index: 3 !important;
            cursor: grab;
            &-fill {
                background-color: $tertiary;
                border-color: $tertiary;
                border-radius: 100%;
                position: absolute;
                top: -2.5px;
                left: -2.5px;
                height: 33px;
                width: 33px;
                line-height: 33px;
                &:focus-visible {
                    outline: none;
                    border-radius: 100%;
                }
            }
            &-tooltip {
                position: absolute;
                background: $text-medium-grey;
                border-radius: 2px;
                top: -40px;
                right: -3px;
                width: 34px;
                height: 26px;
                padding: 4px;
                font-size: 14px;
                &:after {
                    content: "";
                    position: absolute;
                    background: $text-medium-grey;
                    height: 10px;
                    width: 10px;
                    bottom: -5px;
                    left: 12px;
                    transform: rotate(45deg);
                    border-radius: 1px;
                }
            }
            &:active {
                cursor: grabbing;
            }
            &:focus-visible {
                transition: $fast;
                box-shadow: 0 0 0 3px $tertiary;
                outline: none;
                border-radius: 100%;
            }
        }
        &-mark {
            height: 10px;
            width: 2px;
            background: $white;
            top: 11px;
            margin: 0 calc(15px);
            &:hover {
                cursor: pointer;
            }
            &-number {
                position: absolute;
                top: 2rem;
                left: -3px;
                color: $text-medium-grey;
                font-weight: $font-heavy;
                font-size: 14px;
                display: none;
            }
            &:nth-of-type(4),
            &:nth-of-type(9),
            &:nth-of-type(14) {
                .input__slider-mark-number {
                    display: block;
                }
            }
        }
        &-labels {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            span:first-child {
                text-align: left;
            }
            span:last-child {
                text-align: right;
            }
        }
        .input__slider-mark:nth-last-of-type(11) {
            background: none;
        }
    }
}

.on-change-valid {
    padding: 0.5rem;
    background: $error;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: $font-heavy;
    margin: 0.25rem 0 0.65rem 0;
    &-textarea {
        border: 0 !important;
        padding: 0.5rem !important;
        margin: 0 1rem 0.5rem;
        width: auto;
    }
}

@media (min-width: $breakpoint-sm) {
    .input {
        &__slider {
            &-mark {
                &-number {
                    display: block;
                }
            }
        }
        &__textarea {
            textarea {
                margin-left: 53px;
            }
            &-character-count {
                border-bottom: 1px solid $dark-grey;
                padding-left: 69px;
            }
        }
    }
    .on-change-valid {
        &-textarea {
            margin: 0 1rem 0.5rem 70px;
        }
    }
}

.on-change-valid-sm {
    padding: 0.5rem;
    background: $error;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: $font-heavy;
    margin: 0.25rem 0 0.15rem 0;
}
