.button {
    background: $tertiary;
    color: $white;
    font-weight: $font-heavy;
    font-size: 14px;
    padding: 1rem 1rem;
    border: 2px solid $tertiary;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    transition: $fast;
    cursor: pointer;
    i {
        display: inline-block;
        font-size: 14px;
    }
    &__outline {
        background: none;
        color: $tertiary;
        @media (hover: hover) {
            &:hover {
                color: #fff;
                transition: $fast;
            }
        }
        &:active {
            color: #fff;
            transition: $fast;
        }
    }
    @media (hover: hover) {
        &:hover {
            background: $dark-tertiary;
            border: 2px solid $dark-tertiary;
            transition: $fast;
            &:not(:disabled) .button__abs-icon-left {
                i {
                    animation: this_way_left 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                }
            }
            &:not(:disabled) .button__abs-icon-right {
                i {
                    animation: this_way_right 500ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                }
            }
        }
    }
    &:active {
        background: $dark-tertiary;
        border: 2px solid $dark-tertiary;
        transition: $fast;
    }
    &:disabled {
        cursor: not-allowed;
        background: $tertiary;
        border-color: $tertiary;
    }
    &__abs-icon-right {
        position: relative;
        margin-right: 1.5rem;
        i {
            position: absolute;
            right: -30px;
            top: 1px;
        }
    }
    &__abs-icon-left {
        position: relative;
        margin-left: 1.5rem;
        i {
            position: absolute;
            left: -30px;
            top: 1px;
        }
    }
}

@media (min-width: $breakpoint-sm) {
    .button {
        font-size: 16px;
        padding: 1rem;
        &__abs-icon-right {
            i {
                top: 3px;
            }
        }
        &__abs-icon-left {
            i {
                top: 3px;
            }
        }
    }
}
