.footer {
    background: $dark-teal;
    padding: 0;
    &__container {
        background: $dark-teal;
        max-width: 1234px;
        margin: auto;
    }
    &__mission-container {
        padding: 32px;
     }
     &__mission {
        .footer-logo {
            max-width: 254px;
            width: 100%;
        }
        p {
            color: $white;
            margin: 24px 0 0 0;
        }
     }
    &__hr-x {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-top: 1px solid $grey;
        opacity: 0.2;
        width: 100%;
        margin: 0 30px;
    }
    &__cta-container {
        padding: 32px;
        position: relative;
    }
    &__hr-y {
        display: none;
    }
    &__cta {
        a, i, p, small {
            color: $white;
        }
        a {
            font-weight: bold;
            font-size: 18px;
            @media(hover: hover) {
                &:hover {
                    i {
                        animation: this_way_right_footer 500ms;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease;
                    }
                }
            }
        }
        i {
            font-size: 14px;
            color: $white;
            margin-left: 0.5rem;
            display: inline-block;
        }
        p {
            margin: 16px 0 24px;
        }
    }
}

 @media(min-width: $breakpoint_sm) {
    .footer {
        &__mission-container {
            padding: 40px 32px;
        }
        &__mission {
            .footer-logo {
                max-width: 200px;
            }
            p {
                margin: 35px 0 0 0;
            }
        }
        &__cta-container {
            padding: 40px 32px;
        }
        &__hr-x {
            display: none;
        }
        &__hr-y {
            display: block;
            border-top: 0;
            border-bottom: 0;
            border-left: 0px;
            border-right: 1px solid $grey;
            opacity: 0.2;
            position: absolute;
            margin: 40px 0;
            top: 0;
            bottom: 0;
            left: 0;
        }
    }
}

@media(min-width: $breakpoint_md) {
    .footer {
        &__mission-container {
            padding: 50px 32px;
            max-width: 460px;
        }
        &__mission {
            .footer-logo {
                max-width: 260px;
            }
            p {
                margin: 24px 0 0 0;
            }
        }
        &__cta-container {
            padding: 50px 32px 65px 65px;
            max-width: 700px;
            margin-left: 0;
        }
    }
    .footer-call-to-action{
        a {
            font-size: 20px;
        }
    }
}

@media(min-width: $breakpoint_lg) {
    .footer {
        &__mission {
            .footer-logo {
                max-width: 330px;
            }
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }
        &__cta {
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

@media(min-width: $breakpoint_xl) {
    .footer {
        &__mission-container {
            padding: 65px 32px;
            margin-right: 33px;
        }
        &__cta-container {
            padding: 65px 12px 65px 95px;
            max-width: 800px;
            margin-left: 20px;
        }
    }
}
