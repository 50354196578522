.loader {
    margin: 1rem 0;
    width: 100%;
    height: 20px;
    background-color: $grey;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    cursor: progress;
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        animation: shimmer 0.8s infinite ease-in-out;
        content: '';
    }
}

.large {
    height: 50px;
}

.block {
    height: 200px;
}