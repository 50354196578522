.modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    top: 0;
    left: 0;
    z-index: 4;
    &__hide {
        z-index: -2;
        opacity: 0;
        transition: $fast;
    }
    &__show {
        opacity: 1;
        transition: opacity $medium;
    }
    &__body {
        &-save {
            max-width: 100%;
            transition: $fast;
        }
        &__hide {
            margin-top: -10000px;
            transition: $super-slow ease;
        }
        &__show {
            margin-top: 0;
            transition: $medium ease;
        }
        &-close {
            right: 0;
            top: 0;
            margin: 1.5rem;
            position: absolute;
            border-bottom: none !important;
        }
        &-cancel {
            margin: 1rem 0;
        }
        &-inner {
        background: $white;
        border-radius: 6px;
        max-width: 568px;
        padding: 1.5rem;
        position: relative;
            h1 {
                font-size: 28px;
                line-height: 35px;
                color: $dark-teal;
            }
            i {
                color: $tertiary;
                cursor: pointer;
            }
            strong {
                font-weight: $font-heavy;
                color: $text-light-teal;
                margin: 1rem 0 1.5rem;
                display: block;
            }
            a {
                color: $tertiary;
                font-weight: $font-heavy;
                border-bottom: 2px solid $tertiary;
                line-height: 18px;
                display: inline-block;
                &:hover {
                    @media(hover: hover) {
                        color: $dark-tertiary;
                        border-color: $dark-tertiary;
                    }
                }
            }
            label {
                font-size: 14px;
                line-height: 19px;
                font-weight: $font-heavy;
                color: $text-dark-teal;
                display: block;
            }
            .button {
                width: 100%;
                margin: 1.5rem auto 0.5rem;
            }
        }
    }
}

@media(min-width: $breakpoint-sm) {
    .modal {
        &__body {
            &-cancel {
                margin: 1.5rem 0 0;
            }
            &-inner {
                padding: 3.5rem;
                h1 {
                    margin-top: 0;
                }
            }
            &-save {
                max-width: 170px;
                transition: $fast;
            }
        }
    }
}